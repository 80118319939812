import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import logger from "./logger";

const name = "cart"
const initialState = {
    cartInfo: {}
}

export const getCart = createAsyncThunk(`${name}/getCart`, async (cartToken) =>
    logger({
        method: "POST",
        url: `/api/cart/get-cart`,
        body: {
            cartID: localStorage.getItem("cart_id") || ""
        }
    })
);

export const addToCart = createAsyncThunk(`${name}/addToCart`, async (data) =>
    logger({
        method: "POST",
        url: `/api/cart/add-to-cart`,
        body: {
            ...data,
            cartID: localStorage.getItem("cart_id") || ""
        }
    })
);

export const deleteFromCart = createAsyncThunk(`${name}/deleteFromCart`, async (_id) =>
    logger({
        method: "POST",
        url: `/api/cart/delete-from-cart`,
        body: {
            _id,
            cartID: localStorage.getItem("cart_id") || ""
        }
    })
);

export const editCart = createAsyncThunk(`${name}/editCart`, async (data) =>
    logger({
        method: "POST",
        url: `/api/cart/edit-cart`,
        body: {
            ...data,
            cartID: localStorage.getItem("cart_id") || ""
        }
    })
);

export const cartSlice = createSlice({
    name,
    initialState,
    reducers: {
        cleanCart: (state, {payload}) => {
            state.cartInfo = {}
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            addToCart.fulfilled,
            getCart.fulfilled,
            deleteFromCart.fulfilled,
            editCart.fulfilled
        ), (state, { payload }) => {
            state.cartInfo = payload;
            payload._id && localStorage.setItem("cart_id", payload._id)
        });
    }
});

export const selectCartData = (state) => state.cart.cartInfo;

export default cartSlice.reducer;
export const {cleanCart} = cartSlice.actions
