import {combineReducers, configureStore} from '@reduxjs/toolkit'
import  authReducer from "./auth";
import productsReducer from "./products";
import commonReducer from  "./common";
import cartReducer from "./cart"

const combinedReducers = combineReducers({
    auth: authReducer,
    products: productsReducer,
    common: commonReducer,
    cart: cartReducer
})

export const store = configureStore({
    reducer:combinedReducers,
})