import {Routes, Route} from "react-router";
import Home from "./pages/home";
import Products from "./pages/products";
import AdminRouters from "./admin/routes";
import Header from "./layout/Header";
import ProductDetails from "./pages/productDetails";
import Basket from "./pages/basket";
import NotFound from "./pages/notFound";
import PersonalPage from "./pages/PersonalPages";
import ProductSearch from "./pages/productSearch";



export default function Routers() {

    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/admin/*" element={<AdminRouters/>}/>
                <Route path="products/:category/:subCategory/:subSubCategory" element={<Products/>}/>
                <Route path="products/:category/:subCategory" element={<Products/>}/>
                <Route path="products/:category" element={<Products/>}/>
                <Route path="products" element={<ProductSearch/>}/>
                <Route path="product_details/:url" element={<ProductDetails/>}/>
                <Route path="basket" element={<Basket/>}/>
                <Route path="/personal-page/*" element={<PersonalPage/>}/>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    )
}