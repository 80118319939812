import Routers from "./routes";
import {useSelector} from "react-redux";
import {selectLoading} from "./redux/common";
import {CircularProgress} from "@mui/material";
import {Route, Routes} from "react-router";
import AdminRouters from "./admin/routes";
import './App.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    const loading = useSelector(selectLoading);

    return (
        <div className="App">
            {loading && <div className="loading">
                <CircularProgress/>
            </div>}
            <Routes>
                <Route path="/*" element={<Routers />} />
                <Route path="/admin/*" element={<AdminRouters />} />
            </Routes>
        </div>
    );
}

export default App;
