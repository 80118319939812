import {useDispatch, useSelector} from "react-redux";
import {
    addProduct, deleteProduct, editProduct,
    getAllProducts, getCategories,
    getProductsByCategory,
    selectCategories,
    selectProductsData
} from "../../redux/products";
import {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {isNotNull} from "../../helper";
import "./style.scss"
import {setLoading} from "../../redux/common";

export default function AdminProducts() {
    const categories = useSelector(selectCategories);
    const allProducts = useSelector(selectProductsData)
    const dispatch = useDispatch()
    const [addPopUp, setAddPopUp] = useState(false);
    const [edit, setEdit] = useState(false);
    const [categoriesdata, setCategoriesdata] = useState([]);
    const [data, setData] = useState({
        title: '',
        description: '',
        category_id: '',
        quantity: '',
        price: '',
        sale_price: '',
        image: '',
        url: ''
    });

    useEffect(() => {
        dispatch(getAllProducts())
        dispatch(getCategories())
    }, [])

    useEffect(() => {
        if (isNotNull(categories)) {
            const categoriesArr = [];
            categories.forEach(cat => {
                if (cat.children.length) {
                    cat.children.forEach(subCat => {
                        if (subCat.children.length) {
                            subCat.children.forEach(el => {
                                categoriesArr.push({
                                    title: el.title,
                                    category_id: el._id
                                })
                            })
                        }
                    })
                }
            })
            setCategoriesdata(categoriesArr)
        }
    }, [categories]);


    const handleAddProduct = (e) => {
        e.preventDefault()
        dispatch(addProduct(data))
            .then(() => {
                setAddPopUp(false)
            })
    }

    const handleEditProduct = (e) => {
        e.preventDefault();
        dispatch(setLoading(true))
        dispatch(editProduct(data))
            .then(() => {
                dispatch(setLoading(false))
            })
    }

    const handleDeleteProdct = (_id) => {
        // dispatch(setLoading(true))
        dispatch(deleteProduct(_id))
            .then(() => {
                dispatch(setLoading(false))
            })
    }


    return (
        <main className="admin-page">
            <div className="page-main">
                <h1 className="text-blue-green"> Products</h1>
                <button
                    className="addBtn"
                    onClick={() => {
                        setAddPopUp(true)
                    }}
                >
                    <span>+</span>
                    <span>Add Product</span>
                </button>
                <div className="admin-products-container">
                    <table>
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Qtt</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>{isNotNull(allProducts) && allProducts.map(el => (
                            <tr key={el._id} className="single-product">
                                <td>
                                    <img src={el.image}/>
                                </td>
                                <td>
                                    <p>{el.title}</p>
                                </td>
                                <td>
                                    <p>{el.category_id}</p>
                                </td>
                                <td>
                                    <p>{el.price}</p>
                                </td>
                                <td>
                                    <p>{el.quantity}</p>
                                </td>

                                <td>
                                    <div className="buttons">
                                        <button
                                            onClick={() => {
                                                setEdit(true);
                                                setAddPopUp(true)
                                                setData({
                                                    title: el.title,
                                                    description: el.description,
                                                    category_id: el.category_id,
                                                    quantity: el.quantity,
                                                    price: el.price,
                                                    sale_price: el.sale_price,
                                                    url: el.url,
                                                    _id: el._id
                                                })
                                            }}
                                        >Edit
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleDeleteProdct(el._id)
                                            }}
                                        >Delete
                                        </button>
                                    </div>
                                </td>

                            </tr>
                        ))}</tbody>


                    </table>
                </div>
            </div>
            <Dialog
                open={addPopUp}
                onClose={() => {
                    setAddPopUp(false);
                    setEdit(false)
                    setData({
                        title: '',
                        description: '',
                        category_id: '',
                        quantity: '',
                        price: '',
                        sale_price: '',
                        image: '',
                        url: ''
                    })
                }}
            >
                <form
                    className="login-reg"
                    onSubmit={edit ? handleEditProduct : handleAddProduct}
                >
                    <input
                        type="text"
                        value={data.title}
                        placeholder="title"
                        onChange={(e) => {
                            setData({
                                ...data,
                                title: e.target.value
                            })
                        }}
                    />
                    <input
                        type="text"
                        value={data.description}
                        placeholder="description"
                        onChange={(e) => {
                            setData({
                                ...data,
                                description: e.target.value
                            })
                        }}
                    />
                    <select
                        value={data.category_id || ""}
                        onChange={(e) => {
                            setData({
                                ...data,
                                category_id: e.target.value
                            })
                        }}
                    >
                        {categoriesdata.map((el, i) => (
                            <option
                                value={el.category_id}
                                key={i}
                            >
                                {el.title}
                            </option>
                        ))}
                    </select>
                    <input
                        type="number"
                        value={data.quantity}
                        min={1}
                        placeholder="quantity"
                        onChange={(e) => {
                            setData({
                                ...data,
                                quantity: e.target.value
                            })
                        }}
                    />
                    <input
                        type="text"
                        value={data.price}
                        placeholder="price"
                        onChange={(e) => {
                            setData({
                                ...data,
                                price: e.target.value
                            })
                        }}
                    />
                    <input
                        type="text"
                        value={data.sale_price}
                        placeholder="sale_price"
                        onChange={(e) => {
                            setData({
                                ...data,
                                sale_price: e.target.value
                            })
                        }}
                    />
                    <input
                        type="text"
                        value={data.url}
                        placeholder="url"
                        onChange={(e) => {
                            setData({
                                ...data,
                                url: e.target.value
                            })
                        }}
                    />
                    <input
                        type="file"
                        value={data.file}
                        name='image-file'
                        placeholder="upload image"
                        onChange={(e) => {
                            setData({
                                ...data,
                                image: e.target.files[0]
                            })
                        }}
                    />
                    <button>{
                        edit ? "Eidt" : "Add"
                    }</button>
                </form>
            </Dialog>

        </main>
    )
}