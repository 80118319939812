import Cookies from "js-cookie";
import {API_URL} from "../helper";

const logger = ({ method, url, body}) => {
    if (!method) {
        throw Error("Please specify method for this api call");
    }

    if (!url) {
        throw Error("Please specify url for this api call");
    }

    let headers;
    if(body && body.file){
        headers = {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${Cookies.get("token")}`
        }
    }else{
        headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: Cookies.get("token") || ""
        }
    }

    const formData = new FormData();

    if( body && body.file){
        Object.keys(body.data).forEach(el => {
            formData.append(el, body.data[el])
        })
    }

    const config = {
        method,
        cache: "no-cache",
        headers,
        body: body && body.file ? formData :  JSON.stringify(body)
    };

    return new Promise(async (resolve, reject) => {
        const response = await fetch(API_URL+url, config);
        console.log(API_URL+url, "url")
        if (response.ok) {
            try {
                const result = await response.json();
                resolve(result);
            } catch (error) {
                const result = await response.text();
                resolve(result);
            }
        } else {
            const result = await response.json();
            reject({ ...result });
        }
    });
};

export default logger;
