import {Link} from "react-router-dom";

export default function AdminHome (){
    return (
        <main className="admin-page">
        <div>
            <h1 className="text-blue-green">
                Adminka home
            </h1>
        </div>
        </main>
    )
}