import {useEffect, useRef, useState} from "react";
import {Dialog, Drawer, Popover, Popper} from "@mui/material";
import "./header.scss";
import Cookies from "js-cookie"
import {useDispatch, useSelector} from "react-redux";
import {getUserData, login, logout, register, selectUserData} from "../redux/auth";
import {selectPopup, setLoading, setPopup} from "../redux/common";
import {Link} from "react-router-dom";
import logoImg from "../resourses/images/weShop.png"
import {getCategories, selectCategories} from "../redux/products";
import {isNotNull} from "../helper";
import BasketSvgIcon from "../resourses/icons/BasketSvgIcon";
import {cleanCart, getCart, selectCartData} from "../redux/cart";
import {useNavigate} from "react-router";
import LoginContent from "./LoginContent";
import RegisterContent from "./RegisterContent";
import UserICon from "../resourses/icons/UserIcon";
import SignInIcon from "../resourses/icons/SignInIcon";
import WishIcon from "../resourses/icons/WishIcon";
import Search from "./Search";
import SearchIcon from "../resourses/icons/SearchIcon";
import CloseIcon from "../resourses/icons/CloseIcon";
import {useWindowSize} from "../hooks";
import BurgerIcon from "../resourses/icons/BurgerIcon";

export default function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector(selectCategories)
    const userData = useSelector(selectUserData)
    const cartData = useSelector(selectCartData)
    const popup = useSelector(selectPopup)
    const userRef = useRef(null);
    const {width} = useWindowSize();
    const [catalog, setCatalog] = useState(false);
    const [activeSubCategory, setActiveSubCategory] = useState({slug: categories[0], data: categories[0]?.children});
    const [popper, setPopper] = useState(false);
    const [mobileSearch, setMobileSearch] = useState(false)


    useEffect(() => {
        const token = Cookies.get("token");
        const cartToken = localStorage.getItem("cart_id")
        token && dispatch(getUserData(token))
        cartToken && dispatch(getCart())
        dispatch(getCategories())
    }, []);

    useEffect(() => {
        if (isNotNull(categories)) {
            setActiveSubCategory({
                slug: categories[0].url_key,
                data: categories[0].children
            })
        }
    }, [categories]);

    useEffect(() => {
        if (width > 1024) {
            setMobileSearch(false)
        }
    }, [width])


    const handleLogOut = () => {
        dispatch(logout(Cookies.get("token")))
            .then(() => {
                navigate('/')
                dispatch(cleanCart())
            })
    }

    const invertMobileSearch = () => {
        setMobileSearch(!mobileSearch)
    }

    return (
        <>
            <div className="top-panel">
                <a>Call us +374 00 00 00 00</a>
                <span>en</span>
            </div>
            <header>
                <nav className="header-nav">
                    {width < 768 && <div className="burger">
                        <BurgerIcon/>
                    </div>}
                    <div className="nav-logo">
                        <Link to="/">
                            <img src={logoImg}/>
                        </Link>
                    </div>
                    {width > 768 && <div className="nav-items">
                        <div className="nav-item nav-catalog">
                            <span
                                onClick={() => {
                                    setCatalog(!catalog)
                                }}
                            >Catalog</span>
                        </div>
                        <div className="nav-item">
                            <span>
                                About Us
                            </span>
                        </div>
                    </div>}
                </nav>
                <div className="desctop-search">
                    <Search/>
                </div>
                {mobileSearch && <div className="mobile-search">
                    <Search autoFosuc={true}/>
                    <div className="line"></div>
                    <div
                        onClick={invertMobileSearch}
                        className="close-mobile-search">
                        <CloseIcon/>
                    </div>
                </div>}
                <div className="header-actions">
                    <div className="header-icon-buttons">
                        <div
                            onClick={invertMobileSearch}
                            className="search-icon-mobile"
                        >
                            <SearchIcon/>
                        </div>
                        {Cookies.get("token") && Object.keys(userData).length ?
                            <div
                                onClick={() => {
                                    setPopper(!popper)
                                }}
                                ref={userRef}
                                className="user-icon"
                            >
                                <UserICon/>
                            </div> :
                            <div
                                onClick={() => {
                                    dispatch(setPopup("login"))
                                }}
                                className="signin-icon"
                            >
                                <SignInIcon/>
                            </div>
                        }
                        <div className="basket-icon"
                             onClick={() => {
                                 navigate("/basket")
                             }}
                        >
                            <BasketSvgIcon/>
                            <span>{cartData?.items?.length || "0"}</span>
                        </div>
                    </div>
                </div>
                <Popover
                    open={popper}
                    anchorEl={userRef.current}
                    className="user-popover"
                    onClose={() => setPopper(false)}
                    role={undefined}
                    transition
                    disablePortal
                    anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                >
                    <ul
                        onClick={() => {
                            setPopper(!popper)
                        }}
                        className="user-list"
                    >
                        <li>
                            <Link
                                to="personal-page"
                            >
                                <UserICon/>
                                <p>{userData.name}</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="personal-page/wishlist">
                                <WishIcon/>
                                <p>Wish list</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="personal-page/orders">
                                <span>😟</span>
                                <p>Orders</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="personal-page/addresses">
                                <span>😟</span>
                                <p>Addresses</p>
                            </Link>
                        </li>
                        <li
                            onClick={handleLogOut}
                        >
                            <SignInIcon/>
                            <p>Log out</p>
                        </li>
                    </ul>
                </Popover>
                <Dialog
                    className="auth-dialog"
                    open={Boolean(popup === "login" || popup === "reg")}
                    onClose={() => {
                        dispatch(setPopup(""))
                    }}
                >
                    <div
                        onClick={()=>{dispatch(setPopup(""))}}
                         className="close-icon"
                    ><CloseIcon/></div>
                    <div className="auth-tabs">
                        <button
                            onClick={()=>{dispatch(setPopup("login"))}}
                            className={popup === "login" ? "active": ""}
                        >Login </button>
                        <button
                            onClick={()=>{dispatch(setPopup("reg"))}}
                            className={popup === "reg" ? "active" : ""}
                        >Registration</button>
                    </div>
                    {popup === "login" ? <LoginContent/> :
                        popup === "reg" ? <RegisterContent/> : null}
                </Dialog>
                <Drawer
                    anchor="top"
                    open={catalog && isNotNull(categories)}
                    onClose={() => {
                        setCatalog(false)
                    }}
                >
                    <div className='categories-drower'>
                        <div className="categories-area">
                            {isNotNull(categories) && categories.map(el => (
                                el.children.length ? <div
                                        key={el._id}
                                        onClick={() => {
                                            if (isNotNull(el.children)) {
                                                setActiveSubCategory({
                                                    slug: el.url_key,
                                                    data: el.children
                                                })
                                            }
                                        }}
                                        className={el.url_key === activeSubCategory.slug ? "category-item active" : "category-item"}
                                    ><p>{el.title}</p></div> :
                                    <div
                                        key={el._id}
                                        className={el.url_key === activeSubCategory.slug ? "category-item active" : "category-item"}
                                        onClick={() => {
                                            setCatalog(false)
                                        }}
                                    >
                                        <Link to={`products/${el.url_key}`}>{el.title}</Link>
                                    </div>
                            ))}
                        </div>
                        <div className="subCategories-area">
                            {isNotNull(activeSubCategory.data) && activeSubCategory.data.map(el => (
                                <div className="subCategory-area" key={el._id}>
                                    <div
                                        className="subcategory"
                                        onClick={() => {
                                            setCatalog(false)
                                        }}
                                    >
                                        <Link to={`products/${activeSubCategory.slug}/${el.url_key}`}>{el.title}</Link>
                                    </div>
                                    <div
                                        className="subSubCategories"
                                    >
                                        {isNotNull(el.children) && el.children.map(e => (
                                            <div
                                                key={el._id}
                                                onClick={() => {
                                                    setCatalog(false)
                                                }}
                                            >
                                                <Link
                                                    to={`products/${activeSubCategory.slug}/${el.url_key}/${e.url_key}`}
                                                >
                                                    {e.title}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Drawer>
            </header>
        </>
    )
}