import Slider from "react-slick";
import ProductItem from "./ProductItem";

export default function ProductSlider ({data}){
    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: data.length > 5 ? 5 : data.length,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1778,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            }
        ]
    };


    return(
            <Slider {...settings}>
                {data.map(el => <ProductItem key={el._id} el={el}/>)}
            </Slider>
    )
}