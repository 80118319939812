export default function UserICon () {
    return(
	    <svg
		    xmlns="http://www.w3.org/2000/svg"
		    x="0px"
		    y="0px"
		    viewBox="0 0 16 16"
		    xmlSpace="preserve"
	    >
		    <path d="M12 9H4a4 4 0 00-4 4v3h16v-3a4 4 0 00-4-4z" />
		    <path d="M12 5V4a4 4 0 00-8 0v1a4 4 0 008 0z" />
	    </svg>
    )
}