import {Link} from "react-router-dom";
import SignInIcon from "../../resourses/icons/SignInIcon";
import UserICon from "../../resourses/icons/UserIcon";
import WishIcon from "../../resourses/icons/WishIcon";
import {useParams} from "react-router";

const personalPages = [
    {
        to: "",
        icon: <UserICon/>,
        title: "Personal page"
    },
    {
        to: "wishlist",
        icon: <WishIcon/>,
        title: "Wish List"
    },
    {
        to: "orders",
        icon: <span>😟</span>,
        title: "Orders"
    },
    {
        to: "addresses",
        icon: <span>😟</span>,
        title: "Addresses"
    },
    {
        to: "settings",
        icon: <span>😟</span>,
        title: "Page settings"
    }
]


export default function PersonalNavigation ({mobile}){
    const params = useParams();

    return(
        <ul
            className={mobile ? "user-list mobile" : "user-list"}
        >
            {personalPages.map((page, index) => (
                <li className={params["*"] === page.to ? "active" : ""} key={index}>
                    <Link
                        to={page.to}
                    >
                        {page.icon}
                        <p>{page.title}</p>
                    </Link>
                </li>
            ))}
            <li
            >
                <SignInIcon/>
                <p>Log out</p>
            </li>
        </ul>
    )
}