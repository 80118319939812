import videoSrc from "../resourses/videos/pexels-max-fischer-5889062.mp4";
import videoSrc1 from "../resourses/videos/video_preview_h264.mp4";
import image from "../resourses/images/shopBanner.jpg"
import Slider from "react-slick";
import {useLayoutEffect, useRef} from "react";
import Button from "./Button";

export default function MainBanner() {
    const settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        duration: 500,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };

    const ref = useRef(document.querySelector("video"));

    useLayoutEffect(() => {
        ref.current.playbackRate = 0.5
    })

    return (
        <section className="main-banner">
            <Slider {...settings}>
                <div className="item">
                    <div className="video">
                        <video
                            autoPlay
                            loop
                            muted
                            ref={ref}
                        >
                            <source
                                src={videoSrc}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="text-area">
                        <h1>Large variety</h1>
                        <p>Find the latest and greatest products with us</p>
                        <Button>Apply now</Button>
                    </div>
                </div>
                <div className="item">
                <div className="image">
                    <img
                        src={image}
                        alt="shop"
                    />
                    <div className="text-area">
                        <h1>Convenient and affordable</h1>
                        <p>Shopping without leaving home</p>
                        <Button>Apply now</Button>
                    </div>
                </div>
                </div>
            </Slider>

        </section>
    )
}