export default function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search"
        >
            <circle cx={11} cy={11} r={8} />
            <path d="M21 21L16.65 16.65" />
        </svg>
    )
}