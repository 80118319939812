import {Link} from "react-router-dom";
import WishIcon from "../../resourses/icons/WishIcon";
import DeleteIcon from "../../resourses/icons/DeleteIcon";
import {useDispatch} from "react-redux";
import {deleteFromCart, editCart, getCart} from "../../redux/cart";
import {useState} from "react";
import Cookies from "js-cookie";
import {LinearProgress} from "@mui/material";
import {setPopup} from "../../redux/common";
import {addToWishList} from "../../redux/auth";

export default function CartItem({product}) {
    const dispatch = useDispatch();
    const [loading, setLoadig] = useState(false);
    const [wish, setWish] = useState(product.isWished)

    const handleDeleteFromCart = () => {
        dispatch(deleteFromCart(product._id))
    }

    const handleWish = () => {
        if(!Cookies.get("token")){
            dispatch(setPopup("reg"))
        }else{
            dispatch(addToWishList(product._id))
            setWish(!wish)
        }
    }

    const handleEditCart = (inc) => {
        setLoadig(true)
        dispatch(editCart({
            _id: product._id,
            qtt: inc ? +product.qtt + 1 : +product.qtt-1
        }))
            .then(()=>{
                setLoadig(false)
            })
    }

    return (
        <div className="cart-item">
            {loading && <div className="cart-item-loader">
                <LinearProgress />
            </div>}
            <div className="image-area">
                <Link to={`/product_details/${product.url}`}>
                    <img src={product.image}/>
                </Link>
            </div>
            <div className="title-desc">
                <Link to={`/product_details/${product.url}`}>
                    <h2 className="text-blue-green">{product.title}</h2>
                    <p className="text-red-yellow">{product.description}</p>
                </Link>
                {product.sale_price ? <div className="price-sale">
                        <h1>{product.sale_price}֏</h1>
                        <span>{product.price}֏</span>
                    </div> :
                    <h1 className="product-price">{product.price}֏</h1>}
            </div>
            <div className="actions">
                <div className="icon-btns">
                    <div
                        onClick={handleWish}
                        className={`wish-icon ${wish && "active"}`}
                    >
                        <WishIcon/>
                    </div>
                    <div
                        className="delete-icon"
                        onClick={handleDeleteFromCart}
                    >
                        <DeleteIcon/>
                    </div>
                </div>
                <div className="change-qtt">
                    <span
                        className={+product.qtt == 1 ? "disabled" : ""}
                        onClick={()=>{
                            if(+product.qtt-1 != 0){
                                handleEditCart(false)
                            }
                        }}
                    >-</span>
                    <p>{product.qtt}</p>
                    <span
                        className={+product.qtt+1 > product.quantity ? "disabled" : ""}
                        onClick={()=>{
                            if(!(+product.qtt+1 > product.quantity)){
                                handleEditCart(true)
                            }
                        }}
                    >+</span>
                </div>
            </div>
        </div>
    )
}