import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import logger from "./logger";
import Cookies from "js-cookie";

const name = "auth"
const initialState = {
    user: {},
    wishList: []
}

export const register = createAsyncThunk(`${name}/register`, async (data, {rejectWithValue}) => {
    try {
        return await logger({
            method: "POST",
            url: `/api/auth/register`,
            body: data,
        })
    }catch (err){
        return rejectWithValue(err)
    }
});

export const login = createAsyncThunk(`${name}/login`, async (data, {rejectWithValue}) => {
    try {
        return await logger({
            method: "POST",
            url: `/api/auth/login`,
            body: data,
        })
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const logout = createAsyncThunk(`${name}/logout`, async (token) =>
    logger({
        method: "POST",
        url: `/api/auth/logout`,
        body: {token},
    })
);
export const getUserData = createAsyncThunk(`${name}/getUserData`, async (token) =>
    logger({
        method: "POST",
        url: `/api/auth/get-user`,
        body: {token},
    })
);

export const addToWishList = createAsyncThunk(`${name}/addToWishList`, async (_id) =>
    logger({
        method: "POST",
        url: `/api/auth/add-to-wish-list`,
        body: {_id},
    })
);

export const getWishList = createAsyncThunk(`${name}/getWishList`, async () =>
    logger({
        method: "GET",
        url: `/api/auth/get-wish-list`
    })
);


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, {payload}) => {
            state.user = payload;
            Cookies.set("token", payload.token)
        });
        builder.addCase(logout.fulfilled, (state, {payload}) => {
            localStorage.removeItem("cart_id");
            Cookies.remove("token")
            state.user = {}
        });
        builder.addCase(getUserData.fulfilled, (state, {payload}) => {
            state.user = payload
        });
        builder.addCase(getWishList.fulfilled, (state, {payload}) => {
            state.wishList = payload
        });
    }
})

export const selectUserData = state => state.auth.user;
export const selectWishList = state => state.auth.wishList;

export default authSlice.reducer