import SearchIcon from "../resourses/icons/SearchIcon";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {searchProduct} from "../redux/products";
import CloseIcon from "../resourses/icons/CloseIcon";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

export default function Search({autoFosuc}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const inputRef = useRef(null)
    const [value, setValue] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value) {
            dispatch(searchProduct(value))
                .then(() => {
                    navigate({
                        pathname: "products",
                        search: `?search=${value}`
                    })

                })
        }
    }

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search)
        const querySearch = queryParams.get("search");
        querySearch ? setValue(querySearch) : setValue("");
    }, [params])

    return (
        <div className="search-area">
            <form
                onSubmit={handleSubmit}
            >
                <input
                    type="text"
                    placeholder="Search"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    ref={inputRef}
                    autoFocus={autoFosuc}
                />
                {value && <div
                    className="reset-search"
                    onClick={(e)=>{
                        setValue("");
                        inputRef.current.focus()
                    }}
                >
                    <CloseIcon/>
                </div>}
                <button>
                    <SearchIcon/>
                </button>
            </form>
        </div>
    )
}