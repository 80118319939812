import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getWishList, selectWishList} from "../../redux/auth";
import ProductItem from "../../conponents/ProductItem";
import {isNotNull} from "../../helper";

export default function WishList (){
    const dispatch = useDispatch();
    const wishList = useSelector(selectWishList)

    useEffect(()=>{
        dispatch(getWishList())
    }, [])

    return(
        <div className="personal-right">
            <div className="wish-list">
                {isNotNull(wishList) && wishList.map((el, i)=> (
                    <ProductItem el={el} key={i} />
                ))}
            </div>
        </div>
    )
}