import "./style.scss";
import emoji from "../../resourses/images/emogy.png"
import {useEffect, useState} from "react";


export default function NotFound () {
    const [left, setLeft] = useState({
        top: "50%",
        left: "50%"
    })


    useEffect(()=>{
        const {innerWidth: width, innerHeight: height} = window;

        document.addEventListener("mousemove", (e)=>{
            setLeft({
                top:( (e.screenY*100/height) > 68 ? 68 : (e.screenY*100/height).toString()) + "%",
                left: ((e.screenX*100/width) > 65 ? 65 : (e.screenX*100/width).toString()) + "%"
            })
        })
    }, [])


    return(
        <div className="not-found">
            <div className="emoji">
                <img
                    src={emoji}
                    alt={"emoji"}
                />
                <div className="ey-root left">
                    <span
                        style={left}
                        className="ey"></span>
                </div>
                <div className="ey-root right">
                    <span
                        style={left}
                        className="ey"></span>
                </div>
            </div>
        </div>
    )
}