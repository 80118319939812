import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts, getProdutctDetails, selectProductDetails, selectProductsData} from "../../redux/products";
import "./style.scss"
import {setLoading, setPopup} from "../../redux/common";
import Rate from "../../conponents/Rate";
import ProductSlider from "../../conponents/ProductSlider";
import {addToCart} from "../../redux/cart";
import Button from "../../conponents/Button";
import WishIcon from "../../resourses/icons/WishIcon";
import {addToWishList} from "../../redux/auth";
import Cookies from "js-cookie";

export default function ProductDetails() {
    const {url} = useParams();
    const dispatch = useDispatch();
    const productDetails = useSelector(selectProductDetails);
    const allProducts = useSelector(selectProductsData)
    const [qtt, setQtt] = useState("1")
    const [btnLoading, setBtnLoading] = useState();
    const [wish, setWish] = useState(productDetails.isWished)

    useEffect(() => {
        dispatch(setLoading(true))
        //  ---> LIKE RELATED PRODUCTS <---
        dispatch(getAllProducts())
        url && dispatch(getProdutctDetails(url))
            .then((res) => {
                setWish(res.payload.isWished)
                dispatch(setLoading(false))
            })
    }, [url]);


    const handleAddProductToCart = () => {
        setBtnLoading(true);
        dispatch(addToCart({
            _id: productDetails._id,
            qtt,
        }))
            .then(() => {
                setBtnLoading(false)
            })
    }

    const handleWish = () => {
        if (!Cookies.get("token")) {
            dispatch(setPopup("reg"))
        } else {
            dispatch(addToWishList(productDetails._id))
            setWish(!wish)
        }
    }

    return (
        <main className="page">
            <div className="page-main">
                <div className="product-details-main">
                    <div className="image-area">
                        <div
                            onClick={handleWish}
                            className={`wish-icon ${wish && "active"}`}
                        >
                            <WishIcon/>
                        </div>
                        <img alt="product" src={productDetails.image}/>
                    </div>
                    <div className="details">
                        <div className="s-b">
                            <h1 className="text-blue-green"> {productDetails.title}</h1>
                            {productDetails.sale_price ? <div className="price-sale">
                                    <h1>{productDetails.price}֏</h1>
                                    <span>{productDetails.sale_price}֏</span>
                                </div> :
                                <h1 className="product-price">{productDetails.price}֏</h1>}
                        </div>
                        <div className="rating-product">
                            <Rate/>
                        </div>
                        <div className="descripion">
                            <h2>About the product</h2>
                            <p>{productDetails.description}</p>
                        </div>
                        <div className="add-product-to-cart">
                            <div className="qtt-area sb">
                                <span>Quatity</span>
                                <input
                                    type="number"
                                    value={qtt}
                                    onChange={(e) => {
                                        setQtt(e.target.value)
                                    }}
                                    min={1}
                                    max={productDetails.quantity}
                                />
                            </div>
                            <Button
                                className=" add-to-cart-btn"
                                onClick={handleAddProductToCart}
                                loading={btnLoading}
                            >
                                Add to cart
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="related-products">
                    <h2>Related products</h2>
                    <ProductSlider data={allProducts}/>
                </div>
            </div>
        </main>
    )
}