import React, {useEffect} from "react";
import ProductSlider from "../../conponents/ProductSlider";
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts, selectProductsData} from "../../redux/products";
import MainBanner from "../../conponents/MainBanner";
import {setLoading} from "../../redux/common";

export default function Home() {
    const dispatch = useDispatch();
    const allProducts = useSelector(selectProductsData)

    useEffect(() => {
        dispatch(setLoading(true))
        dispatch(getAllProducts())
            .then(()=>{
                dispatch(setLoading(false))
            })
    }, [])

    return (
        <>
            <MainBanner/>
            <main className="page">
                <div className="page-main">
                    <div className="related-products">
                        <h2>Related products</h2>
                        <ProductSlider data={allProducts}/>
                    </div>
                </div>
            </main>
        </>
    )
}