import {Routes, Route} from "react-router";
import AdminHome from "./index"
import AdminCategories from "./cotegories";
import AdminProducts from "./products";
import {Link} from "react-router-dom";
import "./admin.scss"

const adminNav = [
    {
        title: 'Admin',
        slug: "/admin"
    },
    {
        title: 'Products',
        slug: "/admin/products"
    },
    {
        title: 'Categories',
        slug: "/admin/categories"
    },

]

export default function AdminRouters (){

    return(
        <>
            <nav className="admin-nav">{
                adminNav.map((el, i) => (
                    <div className="item" key={i}>
                        <Link to={el.slug} className="text-blue-green">
                            {el.title}
                        </Link>
                    </div>
                ))
            }</nav>

        <Routes>
            <Route path="/" element={<AdminHome />} />
            <Route path="/categories" element={<AdminCategories />} />
            <Route path="/products" element={<AdminProducts />} />
        </Routes>
        </>
    )
}