import {setLoading, setPopup} from "../redux/common";
import {register} from "../redux/auth";
import {useDispatch} from "react-redux";
import {useState} from "react";
import Input from "../conponents/Input";

export default function RegisterContent({setLoginReg}) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [regData, setRegData] = useState({
        name: "",
        phone_number: "",
        email: "",
        password: ""
    })


    const handleRegister = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setLoading(true))
        dispatch(register(regData))
            .then(res => {
                console.log(res, "res")
                dispatch(setLoading(false));
                if (res.error) {
                    setErrors(res.payload.error)
                } else {
                    dispatch(setPopup("login"))
                    setErrors({})
                }
            })
    }

    return (
        <form className="login-reg"
                 onSubmit={handleRegister}
        >
            <Input
                type="text"
                value={regData.name}
                placeholder="name"
                onChange={(e) => {
                    setRegData({
                        ...regData,
                        name: e.target.value
                    })
                }}
            />
            <Input
                type="text"
                value={regData.phone_number}
                placeholder="phone_number"
                onChange={(e) => {
                    setRegData({
                        ...regData,
                        phone_number: e.target.value
                    })
                }}
                errorMessage={errors.phone_number}
            />
            <Input
                type="text"
                value={regData.email}
                placeholder="email"
                onChange={(e) => {
                    setRegData({
                        ...regData,
                        email: e.target.value
                    })
                }}
                errorMessage={errors.email}
            />
            <Input
                placeholder="password"
                value={regData.password}
                type="password"
                onChange={(e) => {
                    setRegData({
                        ...regData,
                        password: e.target.value
                    })
                }}
            />
            <button>
                Register
            </button>
        </form>
    )
}