import {useState} from "react";
import {setLoading, setPopup} from "../redux/common";
import {login} from "../redux/auth";
import {getCart} from "../redux/cart";
import {useDispatch} from "react-redux";
import Input from "../conponents/Input";

export default function LoginContent() {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    const handleLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setLoading(true))
        dispatch(login(loginData))
            .then(res => {
                dispatch(getCart())
                dispatch(setLoading(false));
                if (res.error) {
                    setErrors(res.payload.error)
                } else {
                    dispatch(setPopup(""))
                    setErrors({})
                }
            })
    }


    return (
        <form
            className="login-reg"
            onSubmit={handleLogin}
        >
            <Input
                type="text"
                value={loginData.email}
                placeholder="email"
                onChange={(e) => {
                    setLoginData({
                        ...loginData,
                        email: e.target.value
                    })
                }}
                errorMessage={errors.email}
            />
            <Input
                placeholder="password"
                value={loginData.password}
                type="password"
                onChange={(e) => {
                    setLoginData({
                        ...loginData,
                        password: e.target.value
                    })
                }}
                errorMessage={errors.password}
            />
            <button>
                Login
            </button>
        </form>
    )
}