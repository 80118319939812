import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {getAllProducts, getProductsByCategory, selectProductsData} from "../../redux/products";
import "./style.scss"
import {setLoading} from "../../redux/common";
import ProductItem from "../../conponents/ProductItem";
import {isNotNull} from "../../helper";

export default function Products() {
    const dispatch = useDispatch();
    const {category, subCategory, subSubCategory} = useParams();
    const slug = subSubCategory || subCategory || category;
    const productsData = useSelector(selectProductsData)

    useEffect(() => {
        dispatch(setLoading(true))
        slug && dispatch(getProductsByCategory(slug))
            .then(()=>{dispatch(setLoading(false))})
    }, [slug])



    return (
        <main className="page">
            <div className="page-main products">
                {isNotNull(productsData) ? <div className="products-container">
                    {productsData?.map(el => <ProductItem el={el}/>)}
                </div> : <h1>
                    Sorry, it's still empty ։ 😟
                </h1>}

            </div>
        </main>
    )
}