import "./style.scss"
import {Route, Routes} from "react-router";
import Personal from "./Personal";
import WishList from "./WishList";
import PersonalNavigation from "./PersonalNavigation";
import Main from "./Main";
import {useWindowSize} from "../../hooks";
import {Link, useParams} from "react-router-dom";



export default function PersonalPage() {
    const {width} = useWindowSize();
    const params = useParams()

    return (
        <main className="page">
            <div className="page-main personal-page">
                {width > 1024 && <PersonalNavigation/>}
                {width <=1024 && params["*"] !== "" && <Link to=""> Personal page</Link>}
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/wishlist" element={<WishList/>}/>
                    <Route path="/orders" element={<div className="personal-right"> __orders__ </div>}/>
                    <Route path="/addresses" element={<div className="personal-right"> __address__ </div>}/>
                    <Route path="/settings" element={<Personal/>}/>
                </Routes>
            </div>
        </main>
    )
}