import Rate from "./Rate";
import {Link} from "react-router-dom";

export default function ProductItem({el}) {

    return (
        <div
            className="product-item" key={el._id}
            onClick={()=>{
                window.scrollTo({top:0, behavior: "smooth"})
            }}
        >
            <Link
                onClick={()=>{
                    window.scrollTo({top:0, behavior: "smooth"})
                }}
                to={`/product_details/${el.url}`}>
                <div className="image-area">
                    <img src={el.image}/>
                </div>
                <div className="details">
                    <h2 className="text-blue-green">{el.title}</h2>
                    <h4 className="text-red-yellow">{el.description}</h4>
                    <p>
                        <span>{el.price}֏</span>
                        <span>{el.sale_price}֏</span>
                    </p>
                </div>
            </Link>
        </div>
    )
}