import {useDispatch, useSelector} from "react-redux";
import { selectSearchData} from "../../redux/products";
import ProductItem from "../../conponents/ProductItem";
import {isNotNull} from "../../helper";

export default function ProductSearch() {

    const searchProducts = useSelector(selectSearchData)


    return (
        <main className="page">
            <div className="page-main products">
                {isNotNull(searchProducts) ? <div className="products-container">
                    {searchProducts?.map((el, i) => <ProductItem key={i} el={el}/>)}
                </div> : <h1>
                    Sorry, No Search Result ։ 😟
                </h1>}
            </div>
        </main>
    )
}