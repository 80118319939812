import {CircularProgress} from "@mui/material";

export default function Button (props){
    return(
        <button
            className={`standart-btn ${props.className || ""}`}
            onClick={props.onClick}
            disabled={!!props.loading}
        >
            {props.loading ?
                <CircularProgress/>
                : props.children
            }
        </button>
    )
}