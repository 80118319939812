import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import logger from "./logger";

const name = "products"
const initialState = {
    data: [],
    categories: [],
    productDetails: {},
    searchData: []
}

export const addCategory = createAsyncThunk(`${name}/addCategory`, async (data) =>
    logger({
        method: "POST",
        url: `/admin/categories/add-category`,
        body: data
    })
);
export const editCategoryById = createAsyncThunk(`${name}/editCategoryById`, async (data) =>
    logger({
        method: "POST",
        url: `/admin/categories/edit-category`,
        body: data
    })
);
export const deleteCategoryById = createAsyncThunk(`${name}/deleteCategoryById`, async (data) =>
    logger({
        method: "POST",
        url: `/admin/categories/delete-category`,
        body: data
    })
);

export const getCategories = createAsyncThunk(`${name}/getCategories`, async () =>
    logger({
        method: "GET",
        url: `/api/categories/get-categories`,
    })
);

export const getCategoriesAdmin = createAsyncThunk(`${name}/getCategories`, async () =>
    logger({
        method: "GET",
        url: `/admin/categories/get-categories`,
    })
);
export const getProductsByCategory = createAsyncThunk(`${name}/getProductsByCategory`, async (slug) =>
    logger({
        method: "GET",
        url: `/api/products/get-products-by-category?slug=${slug}`,
    })
);

export const getProdutctDetails = createAsyncThunk(`${name}/getProdutctDetails`, async (url, {rejectWithValue}) => {
    try {
        return await logger({
            method: "GET",
            url: `/api/products/get-product-details?url=${url}`,
        })
    } catch (e) {
        rejectWithValue(e)
    }
});

export const searchProduct = createAsyncThunk(`${name}/searchProduct`, async (value, {rejectWithValue}) =>
    await logger({
        method: "GET",
        url: `/api/products?search=${value}`,
    })
);

export const getAllProducts = createAsyncThunk(`${name}/getAllProducts`, async (slug) =>
    logger({
        method: "GET",
        url: `/admin/products/get-all-products`,
    })
);

export const addProduct = createAsyncThunk(`${name}/addProduct`, async (data) =>
    logger({
        method: "POST",
        url: `/admin/products/add-product`,
        body: {
            file: true,
            data
        }
    })
);

export const editProduct = createAsyncThunk(`${name}/editProduct`, async (data) =>
    logger({
        method: "POST",
        url: `/admin/products/edit-product`,
        body: data.image ? {
            file: true,
            data
        } : data
    })
);

export const deleteProduct = createAsyncThunk(`${name}/deleteProduct`, async (_id) =>
    logger({
        method: "POST",
        url: `/admin/products/delete-product`,
        body: {_id}
    })
);

export const productSlice = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getProdutctDetails.fulfilled, (state, {payload}) => {
            state.productDetails = payload
        });
        builder.addCase(searchProduct.fulfilled, (state, {payload}) => {
            state.searchData = payload
        });
        builder.addMatcher(isAnyOf(getCategories.fulfilled, addCategory.fulfilled, editCategoryById.fulfilled, deleteCategoryById.fulfilled), (state, {payload}) => {
            state.categories = payload;
        });
        builder.addMatcher(isAnyOf(getProductsByCategory.fulfilled, addProduct.fulfilled, getAllProducts.fulfilled, editProduct.fulfilled, deleteProduct.fulfilled), (state, {payload}) => {
            state.data = payload;
        });
    }
})

export const selectProductsData = (state) => state.products.data;
export const selectCategories = (state) => state.products.categories;
export const selectProductDetails = (state) => state.products.productDetails;
export const selectSearchData = state => state.products.searchData;

export default productSlice.reducer