export default function Input ({type, value, placeholder, onChange, errorMessage}){

    return(
        <div>
            <input
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            <p className="error-message">{errorMessage}</p>
        </div>
    )
}