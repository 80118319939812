export  default function SingleCategory ({el, setEditCategory, setData, handleDeleteCategory}){
    return(
        <div className="single-category">
            <p className="title">{el.title}</p>
            <div className="buttons">
                <button
                    onClick={()=>{
                        setEditCategory(true)
                        setData({
                            title:el.title,
                            url_key: el.url_key,
                            _id: el._id
                        })
                    }}
                >Edit</button>
                <button
                    onClick={()=>{handleDeleteCategory(el._id)}}
                >Delete</button>
            </div>
        </div>
    )
}