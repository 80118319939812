import "./style.scss"
import {useSelector} from "react-redux";
import {selectCartData} from "../../redux/cart";
import Summary from "./Summary";
import CartItem from "./CartItem";
import {isNotNull} from "../../helper";

export default function Basket() {
    const cart = useSelector(selectCartData);

    return (
        <main className="page">
            <div className="page-main basket-main">
                <div className="content">
                    <div className="container">
                        {isNotNull(cart.items) && cart.items.map((el, i) => (
                            <CartItem
                                product={el}
                                key={i}
                            />
                        ))}
                    </div>
                    <Summary>
                        <div className="s-b">
                            <p>Total Price</p>
                            <p>{cart.total} ֏</p>
                        </div>
                    </Summary>
                </div>
            </div>
        </main>
    )
}