import {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    addCategory,
    deleteCategoryById,
    editCategoryById,
    getCategoriesAdmin,
    selectCategories
} from "../../redux/products";
import "./style.scss"
import {isNotNull} from "../../helper";
import SingleCategory from "./SingleCategory";

export default function AdminCategories() {
    const dispatch = useDispatch();
    const [categoryPopUp, setCategoryPopUp] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [parents, setParents] = useState([])
    const categories = useSelector(selectCategories)
    const [data, setData] = useState({
        title: "",
        url_key: "",
        parent: ""
    })

    useEffect(() => {
        dispatch(getCategoriesAdmin())
    }, [])

    useEffect(() => {
        const parentsArr = [];
        if (isNotNull(categories)) {
            categories.forEach(category => {
                parentsArr.push(category.url_key)
                category.children.forEach(sub => {
                    parentsArr.push(sub.url_key)
                })
            })
            setParents(parentsArr)
        }
    }, [categories]);

    const handleAddCategory = (e) => {
        e.preventDefault()
        dispatch(addCategory(data))
            .then(() => {
                setCategoryPopUp(false);
                setData({
                    title: "",
                    url_key: "",
                    parent: ""
                })
            })
    }

    const handleEditCategory = (e) => {
        e.preventDefault();
        dispatch(editCategoryById(data))
            .then(() => {
                setEditCategory(false);
                setData({
                    title: "",
                    url_key: "",
                    parent: ""
                })
            })
    }

    const handleDeleteCategory = (_id) => {
        dispatch(deleteCategoryById({_id}))
    }

    return (
        <main className="main-container admin-page">
            <div className="admin-categories">
                <h1>Admin categories</h1>
                <button
                    onClick={() => {
                        setCategoryPopUp(true);
                        setData({
                            title: "",
                            url_key: "",
                            parent: ""
                        })
                    }}
                    className="addBtn"
                >
                    <span>+</span>
                    <span>Add New</span>
                </button>

                <div className="categories-container">
                    {
                        isNotNull(categories) && categories.map((el, index)=> (
                            <div className="categoriItem" key={index}>
                                <SingleCategory
                                    el={el}
                                    setEditCategory={setEditCategory}
                                    setData={setData}
                                    handleDeleteCategory={handleDeleteCategory}
                                />
                                {isNotNull(el.children) && el.children.map((el1, index1) => (
                                    <div className="categoriItem c1" key={index1}>
                                        <SingleCategory
                                            el={el1}
                                            setEditCategory={setEditCategory}
                                            setData={setData}
                                            handleDeleteCategory={handleDeleteCategory}
                                        />
                                        {isNotNull(el1.children) && el1.children.map((el2, index2) => (
                                            <div className="categoriItem c2" key={index2}>
                                                <SingleCategory
                                                    el={el2}
                                                    setEditCategory={setEditCategory}
                                                    setData={setData}
                                                    handleDeleteCategory={handleDeleteCategory}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))
                    }
                </div>
                <Dialog
                    open={categoryPopUp}
                    onClose={() => {
                        setCategoryPopUp(false)
                        setData({
                            title: "",
                            url_key: "",
                            parent: ""
                        })
                    }}
                >
                    <form
                        className="login-reg"
                        onSubmit={handleAddCategory}
                    >
                        <input
                            type="text"
                            value={data.title}
                            placeholder="title"
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    title: e.target.value
                                })
                            }}
                        />
                        <input
                            type="text"
                            placeholder="url_key"
                            value={data.url_key}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    url_key: e.target.value
                                })
                            }}
                        />
                        <select
                            value={data.parent || parents[0] || ""}
                            onChange={(e)=> {
                                setData({
                                    ...data,
                                    parent: e.target.value
                                })
                            }}
                        >
                            {parents.map((el, i)=> (
                                <option
                                    value={el}
                                    key={i}
                                >
                                    {el}
                                </option>
                            ))}
                        </select>
                        <button>
                            Add
                        </button>
                    </form>
                </Dialog>
                <Dialog
                    open={editCategory}
                    onClose={() => {
                        setEditCategory(false)
                    }}
                >
                    <form
                        className="login-reg"
                        onSubmit={handleEditCategory}
                    >
                        <input
                            type="text"
                            value={data.title}
                            placeholder="title"
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    title: e.target.value
                                })
                            }}
                        />
                        <input
                            type="text"
                            placeholder="url_key"
                            value={data.url_key}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    url_key: e.target.value
                                })
                            }}
                        />
                        <button>
                            Add
                        </button>
                    </form>
                </Dialog>
            </div>
        </main>

    )
}