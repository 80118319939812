export default function BurgerIcon (){
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
        >
            <path d="M0 0H16V2H0z" />
            <path d="M0 7H16V9H0z" />
            <path d="M0 14H16V16H0z" />
        </svg>
    )
}