import {useSelector} from "react-redux";
import {selectUserData} from "../../redux/auth";
import {useWindowSize} from "../../hooks";
import PersonalNavigation from "./PersonalNavigation";


export default function Main() {
    const {width} = useWindowSize();
    const user = useSelector(selectUserData)

    return width > 1024 ?
        <div className="personal-right">
            <p>Personal page</p>
            <p>he {user.name} !</p>
        </div> :
        <PersonalNavigation mobile={true}/>
}