import { createSlice} from '@reduxjs/toolkit';

const name = "common"
const initialState = {
    loading: false,
    popup: ""
}


export const commonSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, {payload}) => {
            state.loading = payload
        },
        setPopup : (state, {payload}) => {
            state.popup = payload
        }
    }
})

export const selectLoading = state => state.common.loading;
export const selectPopup = state => state.common.popup;
export default commonSlice.reducer
export const {setLoading, setPopup} = commonSlice.actions

