import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getUserData, selectUserData} from "../../redux/auth";
import Cookies from "js-cookie"

export default function Personal (){
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);

    useEffect(()=>{
        dispatch(getUserData(Cookies.get("token")))
    }, [])

    return(
        <div className="personal-right">
            <div className="personal-item">
                <p>Name</p>
                <p>{userData.name}</p>
            </div>
            <div className="personal-item">
                <p>Email</p>
                <p>{userData.email}</p>
            </div>
            <div className="personal-item">
                <p>Phone number</p>
                <p>{userData.phone_number}</p>
            </div>
        </div>
    )
}