export default function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
        >
            <path d="M18 6L6 18"/>
            <path d="M6 6L18 18"/>
        </svg>
    )
}